<template>
  <div class="bannerBox" :style="bannerStyle">
    <div class="swiper-container bannerSwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in bannerList" :key="index">
          <a
            v-if="item.urlType == 1"
            :href="item.url"
            target="_blank"
            :title="item.title"
          >
            <img class="swiper_img" :src="item.fileName" style="width: 100% ; height: 100% ;" />
          </a>
          <a
            v-if="item.urlType == 2"
            :href="`/list/${item.url}`"
            target="_blank"
            :title="item.title"
          >
            <img class="swiper_img" :src="item.fileName" style="width: 100% ; height: 100% ;" />
          </a>
          <a
            v-if="item.urlType == 3"
            :href="`/detail/${0}/${item.url}`"
            target="_blank"
            :title="item.title"
          >
            <img class="swiper_img" :src="item.fileName" style="width: 100% ; height: 100% ;" />
          </a>
          <a v-if="item.urlType == 4" :title="item.title">
            <img class="swiper_img" :src="item.fileName" style="width: 100% ; height: 100% ;" />
          </a>
        </div>
      </div>
      <div class="swiper-pagination swiper-pagination-banner"></div>
      <div class="swiper-button-prev swiper-button-prev-banner"></div>
      <div class="swiper-button-next swiper-button-next-banner"></div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper"
import { getAdvert } from '@/api/common'
export default {
  props: {
    height: {
      type: Number,
      default: 648
    }
  },
  data() {
    return {
      bannerList: [],
      bannerStyle: {}
    }
  },
  mounted() {
    var _this = this

    _this.bannerStyle = {
      height: _this.height + 'px'
    }
    _this.bannerSwiper()
  },
  methods: {
    // 涓撻涓撴爮
    bannerSwiper(){
      var _this = this
      let data = {
        applicationUuid: _this.webId,
        advertType: 1
      }
      getAdvert(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          data.length && data.forEach(line => {
            if (line.fileName && line.fileName !== '') {
              _this.bannerList.push(line)
            }
          })
          this.$nextTick(() => {
            new Swiper(".bannerSwiper", {
              effect : 'fade',
              speed: 300,
              autoplay: 3000,
              // autoplay: {
              //   disableOnInteraction: false,
              // },
              loop: true,
              // pagination: {
              //   el: ".swiper-pagination-banner",
              //   clickable :true,
              // },
              pagination : '.swiper-pagination-banner',
              paginationClickable :true,

              // slidesPerView : 5,
              // spaceBetween : 15,
              // navigation: {
              //   nextEl: '.swiper-button-next-banner',
              //   prevEl: '.swiper-button-prev-banner',
              //   hideOnClick: true,
              // },

              prevButton: '.swiper-button-prev-banner',
              nextButton: '.swiper-button-next-banner',


              observer: true, //淇敼swiper鑷繁鎴栧瓙鍏冪礌鏃讹紝鑷姩鍒濆鍖杝wiper
              observeParents: true, //淇敼swiper鐨勭埗鍏冪礌鏃讹紝鑷姩鍒濆鍖杝wiper
              autoplayDisableOnInteraction: false,   //鐢ㄦ埛鎿嶄綔swiper涔嬪悗閲嶆柊鍚姩autoplay
            })
          })
        }
      })
    },
  }
}
</script>
<style>
.bannerSwiper {
  height: 100%;
}
.bannerBox .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}
.bannerBox .swiper-button-next,
.bannerBox .swiper-button-prev {
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.6;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.bannerBox .swiper-button-next:hover,
.bannerBox .swiper-button-prev:hover {
  opacity: 1;
}
.bannerBox .swiper-button-next {
  right: -50px;
  background-image: url('../../assets/img/y.png');
}
.bannerBox .swiper-button-prev {
  left: -50px;
  background-image: url('../../assets/img/z.png');
}
.bannerBox:hover .swiper-button-next {
  right: 15px;
}
.bannerBox:hover .swiper-button-prev {
  left: 15px;
}
</style>